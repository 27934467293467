import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BrandMark } from "../../images/brand-mark";
import { DegreeLogo } from "../../images/degree-logo";

import unlimitedStick from "../../images/unlimited-packshot-1.png";
import unlimitedSpray from "../../images/unlimited-packshot-2.png";
import unlimitedStressSweatIcon from "../../images/unlimited-stress-sweat.png";
import unlimitedHeatIcon from "../../images/unlimited-heat.png";
import unlimitedMovementIcon from "../../images/unlimited-movement.png";

const DesktopFrames = () => {
  const animationData = {
    "data-0": "position:absolute;top:50%;left:8%;transform:translateY(-50%);opacity:1;",
    "data-600": "position:absolute;top:-80%;left:8%;transform:translateY(-100%);opacity:0;"
  };
  const markData = {
    "data-0": "position:absolute;top:48%;left:55%;transform:translate(-50%, -50%);opacity:1;",
    "data-600": "position:absolute;top:-80%;left:55%;transform:translate(-50%, -50%);opacity:0;"
  };
  const animationDataRight = {
    "data-0": "position:absolute;top:50%;right:5%;transform:translate(0 -50%);opacity:1;",
    "data-600": "position:absolute;top:-80%;right:5%;transform:translate(0, -50%);opacity:0;"
  };
  const backgroundGlow = {
    "data-0":
      "position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);background-size:100%;background-position:center center;width:100%;height:100%;opacity:1;",
    "data-600":
      "position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);background-size:100%;background-position:center center;width:100%;height:100%;opacity:1;",
    "data-750":
      "position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);background-size:100%;background-position:center center;width:100%;height:100%;opacity:1;",
    "data-850":
      "position:absolute;top:50%;left:90%;transform:translate(-50%, -50%);background-size:100%;background-position:center center;width:100%;height:100%;opacity:1;",
    "data-950":
      "position:absolute;top:50%;left:90%;transform:translate(-50%, -50%);background-size:100%;background-position:center center;width:100%;height:100%;opacity:1;",
    "data-1850":
      "position:absolute;top:50%;left:90%;transform:translate(-50%, -50%);background-size:100%;background-position:center bottom;width:100%;height:100%;opacity:.8;",
    "data-2600":
      "position:absolute;top:10%;left:50%;transform:translate(-50%, -50%);background-size:100%;background-position:center bottom;width:100%;height:100%;opacity:.5;"
  };
  const productStick = {
    "data-0": "position:absolute;top:50%;left:46%;transform:translate(-50%, -50%);opacity:1;",
    "data-600": "position:absolute;top:50%;left:48%;transform:translate(-50%, -50%);opacity:1;",
    // "data-750": "position:absolute;top:50%;left:48%;transform:translate(-50%, -50%);opacity:1;",
    "data-950": "position:absolute;top:50%;left:81%;transform:translate(-50%, -50%);opacity:1;",
    "data-1200": "position:absolute;top:50%;left:81%;transform:translate(-50%, -50%);opacity:1;",
    "data-1500": "position:absolute;top:50%;left:81%;transform:translate(-50%, -50%);opacity:1;",
    "data-1650": "position:absolute;top:50%;left:81%;transform:translate(-50%, -50%);opacity:1;",
    "data-1700": "position:absolute;top:50%;left:81%;transform:translate(-50%, -50%);opacity:1;",
    "data-1900": "position:absolute;top:50%;left:81%;transform:translate(-50%, -50%);opacity:1;",
    "data-2400": "position:absolute;top:-50%;left:81%;transform:translate(-50%, -50%);opacity:0;"
  };
  const productSpray = {
    "data-0": "position:absolute;top:50%;left:60%;transform:translate(-50%, -50%);opacity:1;",
    "data-600": "position:absolute;top:50%;left:58%;transform:translate(-50%, -50%);opacity:1;",
    // "data-750": "position:absolute;top:50%;left:58%;transform:translate(-50%, -50%);opacity:1;",
    "data-950": "position:absolute;top:50%;left:95%;transform:translate(-50%, -50%);opacity:1;",
    "data-1200": "position:absolute;top:50%;left:95%;transform:translate(-50%, -50%);opacity:1;",
    "data-1550": "position:absolute;top:50%;left:95%;transform:translate(-50%, -50%);opacity:1;",
    "data-1650": "position:absolute;top:50%;left:95%;transform:translate(-50%, -50%);opacity:1;",
    "data-1700": "position:absolute;top:50%;left:95%;transform:translate(-50%, -50%);opacity:1;",
    "data-1900": "position:absolute;top:50%;left:95%;transform:translate(-50%, -50%);opacity:1;",
    "data-2400": "position:absolute;top:-50%;left:95%;transform:translate(-50%, -50%);opacity:0;"
  };
  const productSprayTNC = {
    "data-0": "position:absolute;bottom:-10%;right:0%;opacity:0;width:100%;",
    "data-600": "position:absolute;bottom:-10%;right:0%;opacity:0;width:100%;",
    "data-750": "position:absolute;bottom:-10%;right:0%;opacity:1;width:100%;",
    "data-950": "position:absolute;bottom:-10%;right:0%;opacity:1;width:100%;",
    "data-1200": "position:absolute;bottom:-10%;right:0%;opacity:1;width:100%;",
    "data-1550": "position:absolute;bottom:-10%;right:0%;opacity:0;width:100%;"
  };

  const messageOne = {
    "data-0": "position:absolute;top:80%;left:10%;opacity:0;",
    "data-600": "position:absolute;top:80%;left:10%;opacity:0;",
    "data-750": "position:absolute;top:80%;left:10%;opacity:0;",
    "data-1200": "position:absolute;top:50%;left:10%;transform:translateY(-50%);opacity:1;",
    "data-1300": "position:absolute;top:50%;left:10%;transform:translateY(-50%);opacity:1;",
    "data-1400": "position:absolute;top:50%;left:10%;transform:translateY(-50%);opacity:1;",
    "data-1700": "position:absolute;top:-50%;left:10%;transform:translateY(-50%);opacity:0;"
  };
  const messageTwo = {
    "data-0": "position:absolute;top:80%;left:5%;opacity:0;",
    "data-600": "position:absolute;top:80%;left:5%;opacity:0;",
    "data-750": "position:absolute;top:80%;left:5%;opacity:0;",
    "data-1200": "position:absolute;top:80%;left:5%;transform:translateY(-50%);opacity:0;",
    "data-1300": "position:absolute;top:80%;left:5%;transform:translateY(-50%);opacity:0;",
    "data-1400": "position:absolute;top:80%;left:5%;transform:translateY(-50%);opacity:0;",
    "data-1500": "position:absolute;top:80%;left:5%;transform:translateY(-50%);opacity:0;",
    "data-1550": "position:absolute;top:50%;left:5%;transform:translateY(-50%);opacity:1;",
    "data-1650": "position:absolute;top:50%;left:5%;transform:translateY(-50%);opacity:1;",
    "data-1700": "position:absolute;top:50%;left:5%;transform:translateY(-50%);opacity:1;",
    "data-2000": "position:absolute;top:50%;left:5%;transform:translateY(-50%);opacity:1;",
    "data-2400": "position:absolute;top:-50%;left:5%;transform:translateY(-50%);opacity:0;"
  };

  return (
    <section className={`rx-hero-advanced`}>
      <div
        className="rx-hero-advanced--bgGlow"
        data-0={backgroundGlow["data-0"]}
        data-600={backgroundGlow["data-600"]}
        data-750={backgroundGlow["data-750"]}
        data-850={backgroundGlow["data-850"]}
        data-950={backgroundGlow["data-950"]}
        data-1850={backgroundGlow["data-1850"]}
        data-2600={backgroundGlow["data-2600"]}
      ></div>
      <Container fluid>
        <Row>
          <Col sm={12}>
            <div
              className="rx-hero-advanced--copy"
              data-0={animationData["data-0"]}
              data-600={animationData["data-600"]}
            >
              <h1>INTRODUCING</h1>
              <strong className="rx-hidden-desktop">NEW</strong>
            </div>
            <div
              className="rx-unlimited-slogan"
              data-0={animationDataRight["data-0"]}
              data-600={animationDataRight["data-600"]}
            >
              <span>UNLIMITED</span>
              <strong>BY</strong>
              <span>
                <DegreeLogo />
              </span>
            </div>
            <div className="rx-hero-advanced--brandmark" data-0={markData["data-0"]} data-600={markData["data-600"]}>
              <BrandMark />
            </div>
            <div
              className="rx-hero-advanced--image rx-stick"
              data-0={productStick["data-0"]}
              data-600={productStick["data-600"]}
              // data-750={productStick["data-750"]}
              data-950={productStick["data-950"]}
              data-1200={productStick["data-1200"]}
              data-1550={productStick["data-1500"]}
              data-1650={productStick["data-1650"]}
              data-1700={productStick["data-1700"]}
              data-1900={productStick["data-1900"]}
              data-2400={productStick["data-2400"]}
            >
              <picture>
                <img className="rx-stick" src={unlimitedStick} width="260" height={"514"} alt="Degree Unlimited" />
              </picture>
            </div>
            <div
              className="rx-hero-advanced--image rx-spray"
              data-0={productSpray["data-0"]}
              data-600={productSpray["data-600"]}
              // data-750={productSpray["data-750"]}
              data-950={productSpray["data-950"]}
              data-1200={productSpray["data-1200"]}
              data-1550={productSpray["data-1550"]}
              data-1650={productSpray["data-1650"]}
              data-1700={productSpray["data-1700"]}
              data-1900={productSpray["data-1900"]}
              data-2400={productSpray["data-2400"]}
            >
              <picture>
                <img className="rx-spray" src={unlimitedSpray} width="126" height={"514"} alt="Degree Unlimited" />
              </picture>
              <div
                className="rx-hero-advanced--image-tnc"
                data-0={productSprayTNC["data-0"]}
                data-600={productSprayTNC["data-600"]}
                data-750={productSprayTNC["data-750"]}
                data-950={productSprayTNC["data-950"]}
                data-1200={productSprayTNC["data-1200"]}
                data-1550={productSprayTNC["data-1550"]}
              ></div>
            </div>

            {/* MESSAGE ONE */}
            <div
              className="rx-hero-advanced--messageOne"
              data-0={messageOne["data-0"]}
              data-600={messageOne["data-600"]}
              data-750={messageOne["data-750"]}
              data-1200={messageOne["data-1200"]}
              data-1300={messageOne["data-1300"]}
              data-1400={messageOne["data-1400"]}
              data-1700={messageOne["data-1700"]}
            >
              <h2>
                <span>UNLIMITED</span> SWEAT &amp; ODOR PROTECTION<sup>*</sup>
              </h2>
              <div>
                <sup>*</sup>up to 96 hours
              </div>
            </div>

            {/* MESSAGE TWO */}
            <div
              className="rx-hero-advanced--messageTwo"
              data-0={messageTwo["data-0"]}
              data-600={messageTwo["data-600"]}
              data-750={messageTwo["data-750"]}
              data-1200={messageTwo["data-1200"]}
              data-1300={messageTwo["data-1300"]}
              data-1400={messageTwo["data-1400"]}
              data-1500={messageTwo["data-1500"]}
              data-1550={messageTwo["data-1550"]}
              data-1650={messageTwo["data-1650"]}
              data-1700={messageTwo["data-1700"]}
              data-2000={messageTwo["data-2000"]}
              data-2400={messageTwo["data-2400"]}
            >
              <h2>
                THE ONLY ANTIPERSPIRANT WITH{" "}
                <span>
                  SmartAdapt<sup>&trade;</sup> Tech
                </span>
              </h2>
              <div className="rx-anim-vertical">
                <div>RESPONDS TO</div>
                <div className="rx-slidingVertical">
                  <span>
                    <img src={unlimitedStressSweatIcon} width="46" height="55" role="presentation" /> STRESS SWEAT
                  </span>
                  <span>
                    <img src={unlimitedHeatIcon} width="61" height="55" role="presentation" /> HEAT
                  </span>
                  <span>
                    <img src={unlimitedMovementIcon} width="61" height="55" role="presentation" /> MOVEMENT
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DesktopFrames;
